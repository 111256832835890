import {createRouter, createWebHashHistory} from "vue-router"

const routes = [
    {
        path: '/login',
        component: () => import('@/views/login.vue'),
    },{
        path: '/watch',
        component: () => import('@/views/children/Watch.vue'),
    },
    // {
    //     path: '/pc',
    //     component: () => import('@/views/pc.vue'),
    // },
    {
        path: '/',
        component: () => import('@/views/Main.vue'),
        children: [
            {
                path: '/index',
                name: 'Index',
                component: () => import('@/views/children/Index.vue'),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: '/ban',
                name: 'BanList',
                component: () => import('@/views/children/BanList.vue'),
                meta: {
                    keepAlive: false
                }
            },
            {
                path: '/management',
                name: 'Management',
                component: () => import('@/views/children/Management.vue'),
                meta: {
                    keepAlive: false
                }
            },
            {
                path: '/rank',
                name: 'Rank',
                component: () => import('@/views/children/Rank.vue'),
                meta: {
                    keepAlive: false
                }
            },
            {
                path: '/game',
                name: 'MyGameHistory',
                component: () => import('@/views/children/MyGameHistory.vue'),
                meta: {
                    keepAlive: false
                }
            },
            {
                path: '/detail/:gameId',
                name: 'GameDetail',
                component: () => import('@/views/children/GameDetail.vue'),
                meta: {
                    keepAlive: false
                }
            }
        ]
    },
    // {
    //     path: '/',
    //     component: () => import('@/views/main.vue'),
    //     // children:[
    //     //     {
    //     //         path:'/lobby',
    //     //         component: () => import('@/views/lobby.vue'),
    //     //     }
    //     // ]
    // }
    // {
    //     path: '/room/:roomId',
    //     component: () => import('@/views/room1.vue'),
    // }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
