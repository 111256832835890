import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueNativeSock from "vue-native-websocket-vue3";
import Store from './store'
import Vuex from 'vuex'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';

import quasarLang from 'quasar/lang/zh-CN'

// Import icon libraries
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

// Import Quasar css
import 'quasar/src/css/index.sass'


const app = createApp(App);
app.use(Quasar, {
    plugins: {
        Dialog,
        Loading,
        Notify,
        LoadingBar
    }, // import Quasar plugins and add here
    lang: quasarLang,
    /*
    config: {
      brand: {
        // primary: '#e46262',
        // ... or all other brand colors
      },
      notify: {...}, // default set of options for Notify Quasar plugin
      loading: {...}, // default set of options for Loading Quasar plugin
      loadingBar: { ... }, // settings for LoadingBar Quasar plugin
      // ..and many more (check Installation card on each Quasar component/directive/plugin)
    }
    */
})
app.use(VueNativeSock, 'ws://localhost:1000', {
    store:Store,
    connectManually: true,
    reconnection:true,
})
app.use(VueVideoPlayer)
// app.use(Vuex)
app.use(router)
app.mount('#app')
export default app;
