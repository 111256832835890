import {createStore} from "vuex";
import main from '../main'
import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';;

export default createStore({
    state: {
        socket: {
            // 连接状态
            isConnected: false,
            // 消息内容
            message: "",
            // 重新连接错误
            reconnectError: false,
            // 心跳消息发送时间
            heartBeatInterval: 2000,
            // 心跳定时器
            heartBeatTimer: 0,
            initMessage: false,
            reconnectCount: 0,
            reconnectDialog: null,
        }
    },
    mutations: {
        // 连接打开
        SOCKET_ONOPEN(state, event) {
            main.config.globalProperties.$socket = event.currentTarget;
            state.socket.isConnected = true;
            // while (!state.socket.initMessage) {
            //     if (state.socket.isConnected) {
            //         let initMessage = {type: 9999}
            //         main.config.globalProperties.$socket.send(JSON.stringify(initMessage));
            //         state.socket.initMessage = true;
            //     }
            // }
            state.socket.heartBeatTimer = setInterval(() => {
                state.socket.isConnected && main.config.globalProperties.$socket.send("ping");
            }, state.socket.heartBeatInterval);
        },
        // 连接关闭
        SOCKET_ONCLOSE(state, event) {
            state.socket.isConnected = false;
            // 连接关闭时停掉心跳消息
            clearInterval(state.socket.heartBeatTimer);
            state.socket.heartBeatTimer = 0;
            state.socket.initMessage = false;
            // Notify.create({
            //     caption: "连接异常",
            //     type: "negative",
            //     message: '与服务器的连接中断,正在尝试重新连接!',
            //     position: 'center',
            //     progress: true,
            //     timeout: 3000 * 10
            // })
            // setTimeout((s) => {
            //     location.reload();
            // }, 3000)
        },
        // 发生错误
        SOCKET_ONERROR(state, event) {
            console.error(state, event);
        },
        // 收到服务端发送的消息
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
        },
        // 自动重连
        SOCKET_RECONNECT(state, count) {
            state.socket.reconnectCount = count;
            //console.info("websocket reconnect...", state, count);
        },
        // 重连错误
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        }
    },
    modules: {}
});
